<template>
  <div
    class="basic-verticle-card"
    @click="goServiceHandler"
  >
    <div class="basic-verticle-card__img-container">
      <v-img
        class="basic-verticle-card__img"
        :src="service.imgUrl || ''"
        :lazy-src="service.imgUrl || ''"
        cover
        aspect-ratio="1"
      />
      <span
        v-if="service.typeName && showHighlightTag"
        class="basic-verticle-card__img-tag mainColor"
      >
        <i
          class="icon"
          :class="{ [`icon-${service.iconName}`]: true }"
        >
          <span class="path1" /><span class="path2" />
        </i>
        {{ service.typeName }}
      </span>
    </div>
    <header class="basic-verticle-card__info-container">
      <div class="basic-verticle-card__title">
        <span
          v-if="service.serviceAddressMode === 2"
          class="basic-verticle-card__tag-list__tag title-tag mainColor"
        >
          社區專屬
        </span>
        <h3 class="title-text">
          {{ service.title }}
        </h3>
      </div>
      <p class="basic-verticle-card__desc">
        {{ service.desc }}
      </p>
      <div class="basic-verticle-card__tag-list">
        <p
          v-if="service.hasPromotion"
          class="basic-verticle-card__tag-list__tag eventOrange"
        >
          優惠中
        </p>
      </div>
      <div
        class="basic-verticle-card__rating start-place-bottom"
        :class="{'hide': service.ratingSummary.totalRatings < 10}"
      >
        <span class="basic-verticle-card__rating__icon">
          <i class="icon-star-filled" />
        </span>
        <span class="basic-verticle-card__rating__score">{{ service.ratingSummary.averageRating }}</span>
        <span class="basic-verticle-card__rating__score-count">({{ service.ratingSummary.totalRatings }})</span>
      </div>
      <div class="basic-verticle-card__price-block">
        <span
          class="basic-verticle-card__price-block__price"
        >{{ priceFormat }}
        </span>
        <span
          v-if="service.minimumPriceOrPricingMethod.isNumber"
          class="basic-verticle-card__price-block__text"
        >起
        </span>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      default: () => {}
    },
    serviceParentTypeId: String | Number,
    showHighlightTag: Boolean
  },
  computed: {
    priceFormat () {
      if (process.server) return 0
      return this.service.minimumPriceOrPricingMethod.isNumber
        ? '$' + this.$setPriceFormat(this.service.minimumPriceOrPricingMethod.content)
        : this.service.minimumPriceOrPricingMethod.content
    }
  },
  methods: {
    goServiceHandler () {
      const categoryId = this.service.typeParentId || this.serviceParentTypeId
      this.$nuxt.$router.push({ name: 'category-category-service', params: { service: this.service.serviceId, category: categoryId } })
    }
  }
}
</script>

<style lang="scss" scoped>
@include basic-verticle-card;

.basic-verticle-card__title {
  .title-tag, .title-text {
    display: inline
  }
}
.basic-verticle-card__desc {
  @include lineClamp(2);
}
</style>
